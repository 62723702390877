module.exports = {
  path: "it",
  locale: "Italiano",
  textEntries: {
    TITLE: "NKS: Nikolas Mosca Full Stack Web Developer",
    DESCRIPTION: [
      `Sono un Full Stack Web Developer specializzato nello sviluppo Backend.`,
      `Ho esperienza in analisi e progettazione Back-end e Front-end.`,
    ].join(" "),
    KEYWORDS: [
      "fullstack",
      "web",
      "developer",
      "backend",
      "frontend",
      "javascript",
      "js",
      "react",
      "angular",
      "vue",
      "app",
      "php",
      "laravel",
      "service",
      "api",
      "rest",
      "code",
      "android",
      "ios",
    ].join(", "),

    ABOUT: "CHI SONO",
    SKILLS: "COMPETENZE",
    EXPERIENCE: "ESPERIENZE",
    PROJECTS: "PROGETTI",
    EDUCATION: "FORMAZIONE",
    CONTACT: "CONTATTI",
    ARTICLE: "ARTICOLO",
    CLICK_HERE: "Clicca qui!",
    MENU: "Menu",
    MORE_INFORMATIONS: "Scopri di più!",
    VISIT_SITE: "Visita il sito!",

    HELLO: "CIAO",
    IAM: "Mi chiamo",
    AGE: "ETÀ",
    ADDRESS: "INDIRIZZO",
    EMAIL: "E-MAIL",
    PHONE: "TELEFONO",
    FREELANCE: "FREELANCE",
    FREELANCE_VALUE: "da Giugno 2016",

    LINKEDIN: "SEGUIMI SU LINKEDIN",
    PRESENTATION: `
            Ciao! Mi chiamo Nikolas Mosca. 
            Sono un <strong>Full Stack Web Developer</strong> specializzato nello sviluppo Back-end.
            Ho esperienza in <strong>analisi e progettazione</strong> Back-end e Front-end.
            Sono flessibile nell'utilizzare <strong>diversi linguaggi di programmazione</strong> tra cui Javascript, 
            PHP, oltre che gestire progetti in autonomia seguendo le esigenze del cliente.
            Lavoro sia con codice puro che con vari Framework.
            Scrivere codice <strong>ottimizzato</strong>, <strong>documentato</strong> e con <strong>test</strong> associati è il mio obbiettivo.
            Cerco sempre di tenermi <strong>aggiornato</strong> con le nuove tecnologie o metodi di lavoro per offrire
            il <strong>miglior risultato</strong> al prodotto finale.
        `,
    CV: "SCARICA IL CURRICULUM",
    BACKEND_SKILLS: "Competenze Professionale Backend",
    FRONTEND_SKILLS: "Competenze Professionale Frontend",
    WORK_EXPERIENCE: "Esperienza Professionale",

    FREELANCE_DESCRIPTION: `
            Analisi e progettazione <strong>Back-end</strong> e <strong>Front-end</strong>.<br/><br/>
            Lavoro sia con codice puro che con vari Framework.<br/><br/>
            Scrivere <strong>codice ottimizzato</strong>, <strong>documentato</strong> e con 
            <strong>test</strong> associati è il mio obbiettivo.<br/><br/>
            Cerco sempre di tenermi <strong>aggiornato</strong> con le nuove tecnologie o metodi di 
            lavoro per offrire il <strong>miglior risultato</strong> al prodotto finale.
        `,
    TEACHER_DESCRIPTION: `
            Offro <strong>formazioni per aziende</strong> sulle nuove tecnologie del web come <strong>JavaScript, React, Vue, PHP e Laravel</strong>. <br/><br/>
            Insegno le <strong>best practices</strong> e <strong>come gestire un progetto</strong> per aiutare il tuo team a rimanere aggiornato sulle ultime tendenze tecnologiche. <br/><br/>
    `,
    WEWELFARE_POSITION: "Fullstack Web Developer",
    WEWELFARE_DESCRIPTION: `
            Wewelfare è un sito web che offre <strong>servizi di Caf, Patronato e servizi rivolti al cittadino online</strong>. <br/><br/> Puoi richiedere ISEE, 730, NASpI e altri 150 servizi di CAF e patronato comodamente da casa tua. <br/><br/> Seleziona il servizio, entra in contatto con un ufficio autorizzato e richiedi la tua pratica online
        `,
    SHIN_POSITION: "Backend Developer",
    SHIN_DESCRIPTION: `
            Shin Software è un’azienda che fornisce una <strong>piattaforma 3D personalizzabile</strong> e orientata al business, che permette di creare qualsiasi tipo di progetto 3D senza bisogno di codifica, in <strong>tempo reale</strong>, in un <strong>ambiente distribuito</strong> indipendente dal dispositivo. Le loro soluzioni 3D trovano spazio in molteplici settori industriali: dall’automotive al manufacturing, dal fashion all’arredamento.
        `,
    TDEVS_POSITION: "Developer Mentor",
    TDEVS_DESCRIPTION: `
            Se sei alla ricerca di un <strong>progetto formativo diverso</strong>, che non si accontenta dei soliti corsi di programmazione, allora sei nel posto giusto. Abbiamo creato un <strong>percorso personalizzato e flessibile</strong> per aiutarti a costruire un bagaglio di esperienze sul campo specifiche sulle tue attitudini.

            Sotto la <strong>guida esperta di professionisti del settore</strong>, svilupperai progetti reali e imparerai le vere metodologie adottate nel mondo del lavoro. In questo modo sarai immediatamente pronto per affrontare le sfide del mercato.
        `,
    IBAKOO_POSITION: "Fullstack Web Developer",
    IBAKOO_DESCRIPTION: `
          Ibakoo è un sito web che offre una <strong>piattaforma di insegnamento virtuale dal vivo negli Stati Uniti</strong>. <br/><br/>
          La loro piattaforma rende facile, comodo e giusto condividere i tuoi talenti unici attraverso una <strong>connessione 1:1 e farti pagare per questo</strong>. <br/><br/>
          Puoi creare facilmente e rapidamente un video di profilo presentando te stesso e ciò che offri, <strong>impostare il tuo programma e le tue tariffe e condividere il tuo link IBAKOO unico sul tuo blog, sito web o social media</strong>
        `,
    WIBO_POSITION: "Fullstack Web Developer",
    WIBO_DESCRIPTION: `
          Wibo è un’azienda che aiuta le aziende a trattenere i talenti utilizzando una <strong>piattaforma di apprendimento</strong> basata sulla comunità e mobile-first. <br/><br/>
          A differenza dell’e-learning tradizionale, combinano contenuti di micro-apprendimento con workshop dal vivo e giochi di apprendimento e garantiscono un tasso di completamento del 98% che si traduce in un minor turnover e una maggiore produttività dei dipendenti
        `,
    CDA_POSITION: "Fullstack Web Developer",
    CDA_DESCRIPTION: `
          CDA Centro Distribuzione Ricambi è un’azienda italiana che si occupa della <strong>distribuzione di ricambi auto</strong> originali e di primo equipaggiamento e olio motore per veicoli europei e asiatici dal 19771. <br/><br/>
          La loro gamma include prodotti di largo consumo come filtri, pastiglie freno, dischi freno, kit frizione, volani, candele e candelette, kit distribuzione, cinghie e pulegge1. <br/><br/>
          Hanno una grande capacità di stoccaggio con 6 magazzini per un totale di 5.000 mq e più di 2.000 clienti in Italia e in tutta Europa
        `,
    BOOLEAN_POSITION: "Tutor e insegnante",
    BOOLEAN_DESCRIPTION: `
            Collaborazione con azienda di <strong>formazione</strong>.<br/>
            Boolean ha l'obbiettivo di insegnare e formare professionisti nel campo della programmazione.<br/>
            Si tratta di un <strong>corso intensivo online in diretta</strong> per far imparare da zero 
            il mestiere di Web Developer e far trovare un nuovo lavoro nelle aziende top del settore.<br/>
            Boolean è un corso di 700 ore finalizzato all'inserimento lavorativo. <br/>
            Niente teoria che rimane solo sui libri, impara skill pratiche che ti preparano ad 
            <strong>entrare subito nel mondo del lavoro</strong>.
        `,
    FULLSTACKISH_DESCRIPTION: `
            Siamo un azienda di sviluppo software.<br/><br/>
            Il nostro obbiettivo è creare <strong>soluzioni software di livello mondiale</strong> con la migliore qualità 
            del codice e di performance.<br/><br/>
            Il nostro team è composto da persone con una mentalità imprenditoriale, quindi cerchiamo 
            sempre di applicare le <strong>migliori soluzioni</strong> e strumenti per migliorare la <strong>qualità</strong>
            dei prodotti che offriamo.
        `,
    DBMEDICA_DESCRIPTION: `
            Collaborazione con azienda di sviluppo software specializzata sui <strong>gestionali medici</strong>.<br/>
            Il software DBMedica è in grado di gestire poliambulatori di qualsiasi dimensione, 
            anche multisede, grazie ad una <strong>sofisticata gestione dei permessi</strong> utente.<br/>
            Grazie al potente strumento di reportistica il manager del centro può controllare tutto 
            l’andamento finanziario, fare previsioni di <strong>fatturato</strong> e, attraverso un pannello di 
            <strong>reportistica</strong>, individuare con precisione tutti i centri di costo, rendiconti delle 
            prestazioni dei medici, incassato, fatturato, lista debitori, il tipo di prestazioni 
            effettuate e fatturate.<br/>
            Con l'<strong>agenda elettronica</strong> gli operatori possono verificare in <strong>tempo reale</strong> le 
            disponibilità dei medici in base al tipo di prestazione richiesta ed inserire l’appuntamento.
        `,
    BIDIFFERENT_DESCRIPTION: `
            Dipendente in una startup il cui obbiettivo era la creazione di una <strong>nuova criptovaluta</strong>.
            Rispetto a quelle già esistenti, come il Bitcoin, questa valuta sarebbe stata <strong>distribuita
            in base al tempo e al tipo di navigazione dell'utente</strong>.<br/>
            Ho partecipato allo sviluppo della piattaforma, creando io stesso la <strong>chrome extension</strong> che 
            si occupava dell'<strong>analisi della navigazione dell'utente</strong>, interfacciandosi tramite una 
            connessione <strong>websocket in tempo reale</strong> per ottenere informazioni riguardo alla 
            categorizzazione di ogni singolo dominio visitato e anonimizzando la navigazione 
            dell'utente per rispettare la sua <strong>privacy</strong>.<br/>
            Ho collaborato anche alla <strong>dashboard</strong> (WebApp realizzata in <strong>AngularJS</strong>) e 
            al <strong>backend</strong> del sistema (Creato in <strong>PHP</strong> puro, con programmazione ad oggetti).
        `,
    HS_DESCRIPTION: `
            Stagista come sviluppatore assieme ad altri freelancer.<br/><br/>
            È stata l'<strong>esperienza determinante</strong> che mi ha permesso di entrare in questo magnifico settore.<br/>
            Mi ha permesso di mettere in pratica tutto quello che avevo sempre imparato e di riuscire 
            a <strong>realizzare software professionali</strong>.<br/><br/>
            Questa esperienza mi ha formato anche sul <strong>lavoro in team</strong> e sul lavoro basato sia sul codice puro
            che su alcuni framework per migliorare l'<strong>efficienza</strong> personale e del lavoro di gruppo.
        `,

    MYPROJECTS: "Progetti",
    MYPROJECTS_CLICK_HERE: "Entra per sapere di più su questo progetto!",

    DIPLOMA_POSITION: "Informatica e linguaggi di programmazione",
    DIPLOMA_DESCRIPTION: `
            Informatica, elettronica e telecomunicazione, sistemi di elaborazione e trasmissione delle
            informazioni, calcolo delle probabilità, statistica e ricerca operativa.
        `,
    WEBDESIGN_POSITION: "Informatica, linguaggi di programmazione, UI",
    WEBDESIGN_DESCRIPTION: `
            Sviluppo, realizzazione e distribuzioni della progettazione delle applicazioni multimediali
        `,

    CONTACT_ME: "Contatti",
    CONTACT_TITLE: "Sentiti libero di contattarmi!",
    CONTACT_NAME: "Nome",
    CONTACT_EMAIL: "Email",
    CONTACT_SUBJECT: "Oggetto",
    CONTACT_MESSAGE: "Messaggio",
    CONTACT_SEND: "Invia",
    CONTACT_PHONE: "Telefono",
    CONTACT_LINKEDIN: "PROFILO LINKEDIN",
    CONTACT_LINKEDIN_LINK: "Clicca qui",
    CONTACT_SITE_INFORMATION:
      "Questo sito è stato realizzato con React e Gatsby",
    CONTACT_404_PAGE: "La mia pagina 404",
    CONTACT_GITHUB_PAGE: "Pagina Github",

    PAGE_NOT_FOUND_BODY: "Mi spiace la pagina richiesta non è stata trovata!",
    PAGE_NOT_FOUND_BUTTON: "Torna alla home",

    PRIVACY_POLICY: `
        <div><h2>Tipologie di Dati raccolti</h2></div>
        Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: Cookie; Dati di utilizzo.<br/><br/>

        Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.<br/>
        I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.<br/>
        Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.<br/>
        Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.<br/>
        L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.<br/><br/>

        L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.<br/><br/>

        <div><h2>Modalità e luogo del trattamento dei Dati raccolti</h2></div>
        <div><h3>Modalità di trattamento</h3></div>
        Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. <br/>
        Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.<br/><br/>

        <div><h3>Base giuridica del trattamento</h3></div>
        Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:<br/><br/>

        l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;<br/>
        il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;<br/>
        il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;
        il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;<br/>
        il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
        È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.<br/><br/>

        <div><h3>Luogo</h3></div>
        I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.<br/>
        I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.<br/><br/>

        L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.<br/><br/>

        L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.<br/><br/>

        <div><h3>Periodo di conservazione</h3></div>
        I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.<br/><br/>

        <div><h2>Pertanto:</h2></div>

        I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
        I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.<br/>
        Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.<br/><br/>

        Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.<br/><br/>

        <div><h2>Finalità del Trattamento dei Dati raccolti</h2></div>
        I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, così come per le seguenti finalità: Statistica.<br/><br/>

        Per ottenere ulteriori informazioni dettagliate sulle finalità del trattamento e sui Dati Personali concretamente rilevanti per ciascuna finalità, l’Utente può fare riferimento alle relative sezioni di questo documento.<br/><br/>

        <div><h2>Dettagli sul trattamento dei Dati Personali</h2></div>
        I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:<br/><br/>

        <div><h2>Statistica</h2></div>
        I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.<br/><br/>

        <div><h2>Google Analytics (Google Ireland Limited)</h2></div>
        Google Analytics è un servizio di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.
        Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.<br/>
        Dati Personali raccolti: Cookie; Dati di utilizzo.<br/><br/>

        Luogo del trattamento: Irlanda – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.<br/><br/>

        <div><h2>Diritti dell’Utente</h2></div>
        Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.<br/><br/>

        In particolare, l’Utente ha il diritto di:<br/><br/>

        revocare il consenso in ogni momento. L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.<br/>
        opporsi al trattamento dei propri Dati. L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.<br/>
        accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.
        verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.<br/>
        ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.<br/>
        ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.
        ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.<br/>
        proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.
        <div><h3>Dettagli sul diritto di opposizione</h3></div>
        Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.<br/><br/>

        Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.<br/><br/>

        <div><h2>Come esercitare i diritti</h2></div>
        Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.<br/><br/>

        <div><h2>Ulteriori informazioni sul trattamento</h2></div>
        <div><h3>Difesa in giudizio</h3></div>
        I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o dei Servizi connessi da parte dell’Utente.<br/>
        L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.<br/><br/>

        <div><h3>Informative specifiche</h3></div>
        Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.<br/><br/>

        <div><h3>Log di sistema e manutenzione</h3></div>
        Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.<br/><br/>

        <div><h3>Informazioni non contenute in questa policy</h3></div>
        Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.<br/><br/>

        <div><h3>Risposta alle richieste “Do Not Track”</h3></div>
        Questa Applicazione non supporta le richieste “Do Not Track”.
        Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive privacy policy.<br/><br/>

        <div><h3>Modifiche a questa privacy policy</h3></div>
        Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso il Titolare . Si prega dunque di consultare regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.<br/><br/>

        Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.<br/><br/>

        <div><h2>Definizioni e riferimenti legali</h2></div>
        <div><h3>Dati Personali (o Dati)</h3></div>
        Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.<br/><br/>

        <div><h3>Dati di Utilizzo</h3></div>
        Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.<br/><br/>

        <div><h3>Utente</h3></div>
        L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.<br/><br/>

        <div><h3>Interessato</h3></div>
        La persona fisica cui si riferiscono i Dati Personali.<br/><br/>

        <div><h3>Responsabile del Trattamento (o Responsabile)</h3></div>
        La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.<br/><br/>

        <div><h3>Titolare del Trattamento (o Titolare)</h3></div>
        La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.<br/><br/>

        <div><h3>Questa Applicazione</h3></div>
        Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.<br/><br/>

        <div><h3>Servizio</h3></div>
        Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.<br/><br/>

        <div><h3>Unione Europea (o UE)</h3></div>
        Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.<br/><br/>

        <div><h3>Cookie</h3></div>
        Piccola porzione di dati conservata all'interno del dispositivo dell'Utente.<br/><br/>

        <div><h3>Riferimenti legali</h3></div>
        La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.<br/><br/>

        Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.
        `,

    PRIVACY_DIALOG: `Continuando la navigazione sul sito accetti i termini della <a href="/it/privacy">privacy policy</a>. Se vuoi rimuovere questo popup clicca OK`,
    PRIVACY_DIALOG_CONFIRM: "OK",
    CONTACT_ERROR_NAME: "Attenzione! Compilare il campo Nome",
    CONTACT_ERROR_EMAIL:
      "Attenzione! Compilare il campo Email con un email valida",
    CONTACT_ERROR_SUBJECT: "Attenzione! Compilare il campo Oggetto",
    CONTACT_ERROR_MESSAGE: "Attenzione! Compilare il campo Messaggio",
    CONTACT_SUCCESS_MESSAGE: "Il tuo messaggio è stato inviato con successo!",
  },

  /* *********************************************************************** */
  /* *********************************************************************** */
  /* *********************************************************************** */
  projects: data => {
    return [
      {
        key: "idi-project",
        years: "2022",
        position: "Visita il sito!",
        title: "IDI Project",
        text: `<strong>IDI project</strong> è un sito web che offre informazioni sulla <strong>distribuzione internazionale</strong>. Il sito offre ai professionisti coinvolti nella creazione di <strong>reti distributive</strong> e nella redazione, negoziazione e gestione di <strong>contratti di distribuzione</strong>: informazioni legali, modelli contrattuali ed esperienze di altri esperti che affrontano gli stessi problemi. Il progetto gestisce diverse integrazioni di <strong>servizi di terze parti</strong> sia per il <strong>pagamento</strong>, membership per la gestione di <strong>conversione di documenti</strong> in molteplici formati, per l'ottimizzazione degli stessi e per la <strong>sincronizzazione con altre piattaforme</strong> utilizzate per la gestione dei dati dei clienti e delle aziende.`,
        url: "https://www.idiproject.com/",
        img: data.idiProject.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "tekima-tubings-store",
        years: "2023",
        position: "Visita il sito!",
        title: "Tubings Store",
        text: `Piattaforma custom fatta in <strong>React</strong> e <strong>PHP</strong> implementata all'interno di un ecosistema <strong>Wordpress</strong> per sopperire all'esigenza di poter proporre uno store con filtri dinamici e adattabili a seconda dei risultati di ricerca in maniera efficace e leggera. <br/><br/> Supportata anche a livello <strong>responsive</strong>, multilingua e <strong>integrata anche con piattaforme terze</strong> per offrire uno store anche all'interno di altre piattaforme per rivenditori comunicando con <strong>Websocket</strong> ai loro sistemi`,
        url: "https://www.tekima.com/tubings-store/",
        img: data.tekimaTubingsStore.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "tekima-labels-store",
        years: "2022",
        position: "Visita il sito!",
        title: "Labels Store",
        text: `Piattaforma custom fatta in <strong>React</strong> e <strong>PHP</strong> implementata all'interno di un ecosistema <strong>Wordpress</strong> per sopperire all'esigenza di poter proporre uno store con filtri dinamici e adattabili a seconda dei risultati di ricerca in maniera efficace e leggera. <br/><br/> Supportata anche a livello <strong>responsive</strong>, multilingua e <strong>integrata anche con piattaforme terze</strong> per offrire uno store anche all'interno di altre piattaforme per rivenditori comunicando con <strong>Websocket</strong> ai loro sistemi`,
        url: "https://www.tekima.com/labels-store/",
        img: data.tekimaLabelsStore.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "tekima-cable-store",
        years: "2021",
        position: "Visita il sito!",
        title: "Cables Store",
        text: `Piattaforma custom fatta in <strong>React</strong> e <strong>PHP</strong> implementata all'interno di un ecosistema <strong>Wordpress</strong> per sopperire all'esigenza di poter proporre uno store con filtri dinamici e adattabili a seconda dei risultati di ricerca in maniera efficace e leggera. <br/><br/> Supportata anche a livello <strong>responsive</strong>, multilingua e <strong>integrata anche con piattaforme terze</strong> per offrire uno store anche all'interno di altre piattaforme per rivenditori comunicando con <strong>Websocket</strong> ai loro sistemi`,
        url: "https://www.tekima.com/cables-store/",
        img: data.tekimaCablesStore.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "adventofcode-2022",
        years: "2022",
        position: "Visita il sito!",
        title: "Advent of code 2022",
        text: `L’<strong>Advent of Code</strong> è un evento annuale che consiste in una serie di piccoli <strong>puzzle di programmazione</strong>. <br/> I puzzle vengono rilasciati quotidianamente dal 1° al 25 dicembre. L’obiettivo è migliorare la propria comprensione del linguaggio di programmazione preferito risolvendo il maggior numero possibile di puzzle. Ho partecipato all’Advent of Code del 2022 e ho trovato le <strong>sfide particolarmente interessanti dal punto di vista logico</strong>. <br/> Questo evento offre l’opportunità di <strong>mettere alla prova le proprie abilità di programmazione</strong> e risolvere problemi in modo creativo. <br/>`,
        url: "https://github.com/NikolasMosca/adventofcode-2022",
        img: data.adventOfCode2022.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "weather-pwa",
        years: "2021",
        position: "Visita il sito!",
        title: "Web App Meteo",
        text: `Progetto realizzato per una <strong>test interview</strong> dove era richiesta la realizzazione di una webapp che mostrasse le <strong>previsioni meteo</strong>. <br/>
                    La web app permette di <strong>cercare una qualsiasi città del mondo</strong> e mostrare lo storico delle temperature, il meteo attuale e le previsioni per la settimana corrente. <br/>
                    È anche possibile utilizzare la <strong>geolocalizzazione</strong> per ricercare le previsioni meteo della città in cui risiedi. <br/>
                    La PWA è stata realizzata con <strong>React</strong>, <strong>Redux</strong> con gli hooks. <br/>
                    Sono stati anche integrati i test con <strong>Cypress</strong> per gli end-to-end test, mentre è stato usato <strong>Jest</strong> per gli automation test. <br/>
                    Come documentazione sui componenti invece è stato utilizzato <strong>storybook</strong>. <br/>`,
        url: "https://musing-mestorf-1a7643.netlify.app/",
        img: data.weatherWebApp.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "2048-game",
        years: "2020",
        position: "Gioca!",
        title: "2048 Game",
        text: `Semplice clone del gioco <strong>2048</strong> fatta nel tempo libero. <br/>
                    Questo progetto è stato realizzato usando <strong>React</strong>.  <br/>
                    Buon divertimento! <br/><br/>
                    Il progetto è anche disponibile su <strong>github</strong>, se ti piace lasciami una <strong>star</strong>`,
        url: "https://festive-albattani-dc5d19.netlify.app/",
        img: data.game2048.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "tic-tac-toe",
        years: "2020",
        position: "Gioca!",
        title: "Tris Game",
        text: `Semplice gioco del <strong>tris</strong>. <br/>
                    Questo progetto è stato realizzato usando <strong>VueJS</strong>.  <br/>
                    Il giocatore può giocare contro una <strong>AI</strong> che ho fatto io personalmente. <br/> 
                    Ovviamente, non è impossibile vincere ma non sarà cosi semplice :) Buon divertimento! <br/><br/>
                    Il progetto è anche disponibile su <strong>github</strong>, se ti piace lasciami una <strong>star</strong>`,
        url: "https://frosty-albattani-20d20c.netlify.com/",
        img: data.tris.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "museo-del-fumetto",
        years: "2020",
        position: "Visita il sito!",
        title: "Museo del fumetto",
        text: `Sito per la gestione degli eventi e mostre legate al mondo del fumetto. </br>
                    Realizzato in maniera dinamica in <strong>Laravel</strong> e <strong>SASS</strong>. <br/>
                    Ho usato le migrations per gestire il versioning del database fatto in <strong>MySQL</strong> 
                    e anche le blade view per la gestione a <strong>componenti</strong> 
                    del template per maggiore ottimizzazione.<br/>
                    Il backend di gestione del sito è scritto in <strong>AngularJS + REST API Laravel</strong>`,
        url: "http://www.museowow.it/",
        img: data.museoFumetto.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "honda",
        years: "2019",
        position: "React native app!",
        title: "App per conferenze",
        text: `App fatta in <strong>React Native</strong> per <strong>IOS</strong> e <strong>Android</strong>. <br/>
                    Questa app è per uso <strong>privato</strong> per le <strong>conferenze</strong> degli impiegati aziendali. <br/>
                    È stato utilizzato anche <strong>OneSignal</strong> per le notifiche sul dispositivo e la gestione degli utenti, insieme ad un webservice apposta per le altre funzionalità`,
        url: false,
        img: data.honda.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "nimrod",
        years: "2018",
        position: "Visita il sito!",
        title: "Nimrod",
        text: `Sito con gestionale custom. <br/>
                    Realizzato in <strong>PHP + Javascript</strong>. <br/>
                    Il sito è stato fatto usando <strong>totalmente codice puro</strong>. <br/>
                    Il backend di gestione del sito è scritto in <strong>PHP</strong>`,
        url: "http://nimrodensemble.com/",
        img: data.nimrod.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "immobilbiella",
        years: "2017",
        position: "Visita il sito!",
        title: "Immobilbiella",
        text: `Sito di vendita immobili con gestionale custom. <br/>
                    Realizzato in <strong>Laravel</strong> e <strong>PHP puro</strong>. <br/>
                    Il sito è stato fatto usando <strong>JQuery</strong> e con <strong>Javascript puro</strong> ad oggetti 
                    per gestire le diverse funzionalità. <br/>
                    Il backend di gestione del sito è scritto in <strong>AngularJS + REST API Laravel</strong>`,
        url: "http://immobilbiella.it/",
        img: data.immobilbiella.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "docilia",
        years: "2017",
        position: "Visita il sito!",
        title: "Docilia Restauri",
        text: `Sito con gestionale custom. <br/>
                    Realizzato in <strong>PHP + Javascript</strong>. <br/>
                    Il sito è stato fatto usando <strong>totalmente codice puro</strong> per ottenere il massimo delle prestazioni e di qualità. <br/>
                    Il backend di gestione del sito è scritto in <strong>PHP</strong>`,
        url: "https://www.dociliarestauri.it/",
        img: data.docilia.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "azimut",
        years: "2017",
        position: "Visita il sito!",
        title: "Azimut",
        text: `Sito con gestionale custom. <br/>
                    Realizzato in <strong>PHP + Javascript</strong>. <br/>
                    Il sito è stato fatto usando <strong>JQuery</strong>. <br/>
                    Il backend di gestione del sito è scritto in <strong>Zend Framework PHP</strong>`,
        url: "https://riabilitazione.com/",
        img: data.azimut.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "jinni",
        years: "2016",
        position: "Visita il sito!",
        title: "Jinni",
        text: `Semplice <strong>webpage</strong> che ho fatto per questo progetto. <br/>
                    Mi sono anche occupato della creazione della <strong>chrome extension</strong> che permetteva di generare un url custom in maniera semplice e veloce. Questo url permetteva di avere analytics su chi, quando e dove ha cliccato il link`,
        url: "https://cranky-jepsen-b1c31a.netlify.com/",
        img: data.jinni.childImageSharp.fluid,
        animated: true,
      },
    ]
  },
}
