module.exports = {
  path: "en",
  locale: "English",
  default: true,
  textEntries: {
    TITLE: "NKS: Nikolas Mosca Full Stack Web Developer",
    DESCRIPTION: [
      `I'm Full Stack Web Developer specializing in Backend development.`,
      `I have experience in Backend and Frontend analysis and development.`,
    ].join(" "),
    KEYWORDS: [
      "fullstack",
      "web",
      "developer",
      "backend",
      "frontend",
      "javascript",
      "js",
      "react",
      "angular",
      "vue",
      "app",
      "php",
      "laravel",
      "service",
      "api",
      "rest",
      "code",
      "android",
      "ios",
    ].join(", "),

    ABOUT: "ABOUT",
    SKILLS: "SKILLS",
    EXPERIENCE: "EXPERIENCE",
    PROJECTS: "PROJECTS",
    EDUCATION: "EDUCATION",
    CONTACT: "CONTACT",
    ARTICLE: "ARTICLE",
    CLICK_HERE: "Click here!",
    MENU: "Menu",
    MORE_INFORMATIONS: "More informations!",
    VISIT_SITE: "Visit the site!",

    HELLO: "HELLO",
    IAM: "I'm",
    AGE: "AGE",
    ADDRESS: "ADDRESS",
    EMAIL: "E-MAIL",
    PHONE: "PHONE",
    FREELANCE: "FREELANCE",
    FREELANCE_VALUE: "till June 2016",

    LINKEDIN: "FOLLOW ME ON LINKEDIN",
    PRESENTATION: `
            Hello! I'm Nikolas Mosca. <strong>Full Stack Web Developer</strong> specializing in Back-end development.
            I have experience in Back-end and Front-end <strong>analysis and development</strong>.
            Well-versed in <strong>numerous programming languages</strong> including JavaScript, PHP, and background 
            in project management and customer relations.
            I work with or without Framework.
            When I write code my focus is on <strong>optimization</strong>, <strong>documentation</strong> and <strong>automation test</strong>.
            I try to improve myself and <strong>keep me updated</strong> with the new technologies or work’s
            methodologies to offer the <strong>best result</strong> with my products.
        `,
    CV: "DOWNLOAD RESUME",
    BACKEND_SKILLS: "Professional Backend Skills",
    FRONTEND_SKILLS: "Professional Frontend Skills",
    WORK_EXPERIENCE: "Work Experience",

    FREELANCE_DESCRIPTION: `
            <strong>Back-end</strong> and <strong>Front-end</strong> analysis and development. <br/><br/>
            I work with or without Framework. <br/><br/>
            When I write code my focus is on <strong>optimization</strong>, <strong>documentation</strong> and <strong>automation test</strong>.<br/><br/> 
            I try to improve myself and <strong>keep me updated</strong> with the new technologies or work’s 
            methodologies to offer the <strong>best result</strong> with my products.
        `,
    TEACHER_DESCRIPTION: `
          I offer <strong>training for companies</strong> on new web technologies such as <strong>JavaScript, React, Vue, PHP and Laravel</strong>. <br/><br/> I teach <strong>best practices</strong> and <strong>how to manage a project</strong> to help your team stay up-to-date on the latest technological trends. <br/><br/>
        `,
    WEWELFARE_POSITION: "Fullstack Web Developer",
    WEWELFARE_DESCRIPTION: `
          Wewelfare is a website that offers <strong>online Caf, Patronato and citizen services</strong>. <br/><br/> You can request ISEE, 730, NASpI and other 150 CAF and patronage services comfortably from your home. <br/><br/> Select the service, get in touch with an authorized office and request your practice online.
        `,
    SHIN_POSITION: "Backend Developer",
    SHIN_DESCRIPTION: `
          Shin Software is a company that provides a <strong>customizable 3D platform</strong> oriented towards business, which allows you to create any type of 3D project without the need for coding, in <strong>real time</strong>, in a <strong>distributed environment</strong> independent of the device. Their 3D solutions find space in multiple industrial sectors: from automotive to manufacturing, from fashion to furniture.
        `,
    TDEVS_POSITION: "Developer Mentor",
    TDEVS_DESCRIPTION: `
          If you are looking for a <strong>different training project</strong>, which is not satisfied with the usual programming courses, then you are in the right place. We have created a <strong>customized and flexible path</strong> to help you build a baggage of specific field experiences on your aptitudes.

          Under the <strong>expert guidance of industry professionals</strong>, you will develop real projects and learn the true methodologies adopted in the world of work. In this way you will be immediately ready to face the challenges of the market.
        `,
    IBAKOO_POSITION: "Fullstack Web Developer",
    IBAKOO_DESCRIPTION:
      "Ibakoo is a website that offers a <strong>live virtual teaching platform in the United States</strong>. <br/><br/> Their platform makes it easy, convenient and fair to share your unique talents through a <strong>1:1 connection and get paid for it</strong>. <br/><br/> You can easily and quickly create a profile video introducing yourself and what you offer, <strong>set your schedule and rates and share your unique IBAKOO link on your blog, website or social media</strong>",
    WIBO_POSITION: "Fullstack Web Developer",
    WIBO_DESCRIPTION:
      "Wibo is a company that helps businesses retain talent using a <strong>community-based and mobile-first learning platform</strong>. <br/><br/> Unlike traditional e-learning, they combine micro-learning content with live workshops and learning games and guarantee a 98% completion rate that translates into lower turnover and increased employee productivity",
    CDA_POSITION: "Fullstack Web Developer",
    CDA_DESCRIPTION:
      "CDA Centro Distribuzione Ricambi is an Italian company that deals with the <strong>distribution of original and first equipment car parts</strong> and engine oil for European and Asian vehicles since 19771. <br/><br/> Their range includes widely used products such as filters, brake pads, brake discs, clutch kits, flywheels, spark plugs and glow plugs, timing kits, belts and pulleys1. <br/><br/> They have a large storage capacity with 6 warehouses for a total of 5,000 square meters and more than 2,000 customers in Italy and throughout Europe",
    BOOLEAN_POSITION: "Tutor and teacher",
    BOOLEAN_DESCRIPTION: `
            Collaboration with <strong>traning</strong> company.<br/>
            Boolean's focus is teach and train new professional developers.<br/>
            It deals with an <strong>intensive online course</strong> for training the developer job 
            to people that haven't any experience with it and to find a new developer job in the best 
            companies of this sector.
            The course has <strong>700 hours</strong> focused on job experience.
            No useless theory but <strong>real skills</strong> for work in real job life.
        `,
    FULLSTACKISH_DESCRIPTION: `
            We are a software development company. <br/><br/>
            Our goal is to ship <strong>world-class software</strong> solutions with the best 
            quality code provided.<br/><br/>
            Our team is composed by people with an entrepreneurial mindset, so we are always 
            looking to apply the <strong>best solutions</strong> and tools to improve the <strong>quality</strong>
            of the products  we deliver.
        `,
    DBMEDICA_DESCRIPTION: `
            Collaboration with a software house specialized in hospistal management.<br/>
            DBMedica software are use to manage any polyclinics, multisite too.<br/>
            It is possible by a <strong>complex users permissions management</strong>.<br/>
            The software has a powerfool report tool that the manager can use to check finalcial performance,
            <strong>turnover forecasts</strong>, detailed costs, medical services, list of debtors and
            type of services provided.<br/>
            <strong>Users can verify in real time the calendar</strong> for medics avaiability in base of service requested 
            and insert new appointments.
        `,
    BIDIFFERENT_DESCRIPTION: `
            Employee in a startup that have as focus to create a <strong>new cryptocurrency</strong>.
            This coin is different than others, as a bitcoin, because <strong>it would be distributed by the time spent and the navigation of the users</strong>.<br/>
            I have partecipated in the platform development and I have created the <strong>chrome extension</strong> myself.
            This extension was used for <strong>tracking and analyse the time and the navigation</strong> of the user. 
            It is connected with the backend by <strong>websocket, a real time connection</strong>.
            The navigation will be converted in anonymous data and domains will be converted as categories for the users's <strong>privacy</strong>.<br/>
            I have collaborated to develop the <strong>dashboard</strong> of the users (webapp in <strong>AngularJS</strong>) and to <strong>backend</strong> system and api (developed in <strong>PHP</strong> with Object-oriented programming).
        `,
    HS_DESCRIPTION: `
            Intern as developer with other freelancers.<br/><br/>
            This was the <strong>determinant experience</strong> that permit me to enter in this beautiful work!<br/>
            It permitted me to do practice and apply all the theory I learned in many years of school and courses.<br/>
            The result was that I can be able to <strong>create a professional software</strong>.<br/><br/>
            This experience formed me to <strong>work in team</strong> and to work and to write good code with or without framework.<br/>
            I was improved in my personal <strong>efficiency</strong> and in the collaborations with others. <br/><br/>
        `,

    MYPROJECTS: "My Projects",
    MYPROJECTS_CLICK_HERE: "Enter to know more about this project!",

    DIPLOMA_POSITION: "IT and programming languages",
    DIPLOMA_DESCRIPTION: `
            Information technology, electronics and telecommunications, information processing 
            and transmission systems, probability calculations, statistics and operations research
        `,
    WEBDESIGN_POSITION: "IT and programming languages, UI",
    WEBDESIGN_DESCRIPTION: `
            Web Design and Development Course Design, Develop and Deploy Web Application
        `,

    CONTACT_ME: "Contact me",
    CONTACT_TITLE: "Feel free to contact me!",
    CONTACT_NAME: "Name",
    CONTACT_EMAIL: "Email",
    CONTACT_SUBJECT: "Subject",
    CONTACT_MESSAGE: "Message",
    CONTACT_SEND: "Send",
    CONTACT_PHONE: "Phone",
    CONTACT_LINKEDIN: "LINKEDIN PROFILE",
    CONTACT_LINKEDIN_LINK: "Click here",
    CONTACT_SITE_INFORMATION: "This site was realized with React and Gatsby",
    CONTACT_404_PAGE: "My 404 page",
    CONTACT_GITHUB_PAGE: "My Github page",

    PAGE_NOT_FOUND_BODY: "Sorry the page requested was not found!",
    PAGE_NOT_FOUND_BUTTON: "Turn in home",

    PRIVACY_POLICY: `
            <div><h2>Methods of processing</h2></div>
            The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.</br>
            The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</br></br>
            
            <div><h2>Legal basis of processing</h2></div>
            The Owner may process Personal Data relating to Users if one of the following applies:</br></br>
            
            Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</br>
            provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</br>
            processing is necessary for compliance with a legal obligation to which the Owner is subject;
            processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</br>
            processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</br>
            In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</br></br>
            
            <div><h2>Place</h2></div>
            The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.</br></br>
            
            Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</br></br>
            
            Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.</br></br>
            
            If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</br></br>
            
            <div><h2>Retention time</h2></div>
            Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</br></br>
            
            <div><h2>Therefore:</h2></div>
            
            Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
            Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</br>
            The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.</br></br>
            
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</br></br>
            
            <div><h2>The purposes of processing</h2></div>
            The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes: Analytics.</br></br>
            
            Users can find further detailed information about such purposes of processing and about the specific Personal Data used for each purpose in the respective sections of this document.</br></br>
            
            <div><h2>Detailed information on the processing of Personal Data</h2></div>
            Personal Data is collected for the following purposes and using the following services:</br></br>
            
            <div><h2>Analytics</h2></div>
            The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</br></br>
            
            <div><h2>Google Analytics (Google Ireland Limited)</h2></div>
            Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
            Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</br>
            Personal Data collected: Cookies; Usage Data.</br></br>
            
            Place of processing: Ireland – Privacy Policy – Opt Out. Privacy Shield participant.</br></br>
            
            <div><h2>The rights of Users</h2></div>
            Users may exercise certain rights regarding their Data processed by the Owner.</br></br>
            
            In particular, Users have the right to do the following:</br></br>
            
            Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</br>
            Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</br>
            Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</br>
            Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</br>
            Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</br>
            Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</br>
            Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</br>
            Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.</br>

            <div><h2>Details about the right to object to processing</h2></div>
            Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</br></br>
            
            Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.</br></br>
            
            <div><h2>How to exercise these rights</h2></div>
            Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</br></br>
            
            <div><h2>Additional information about Data collection and processing</h2></div>
            <div><h3>Legal action</h3></div>
            The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.</br>
            The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</br>
            
            <div><h3>Additional information about User's Personal Data</h3></div>
            In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</br></br>
            
            <div><h3>System logs and maintenance</h3></div>
            For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.</br></br>
            
            <div><h3>Information not contained in this policy</h3></div>
            More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</br></br>
            
            <div><h3>How “Do Not Track” requests are handled</h3></div>
            This Application does not support “Do Not Track” requests.</br>
            To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.</br></br>
            
            <div><h3>Changes to this privacy policy</h3></div>
            The Owner reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. </br></br>
            
            Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.</br></br>
            
            <div><h2>Definitions and legal references</h2></div>
            <div><h3>Personal Data (or Data)</h3></div>
            Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</br></br>
            
            <div><h3>Usage Data</h3></div>
            Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</br></br>
            
            <div><h3>User</h3></div>
            The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</br></br>
            
            <div><h3>Data Subject</h3></div>
            The natural person to whom the Personal Data refers.</br></br>
            
            <div><h3>Data Processor (or Data Supervisor)</h3></div>
            The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</br></br>
            
            <div><h3>Data Controller (or Owner)</h3></div>
            The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</br></br>
            
            <div><h3>This Application</h3></div>
            The means by which the Personal Data of the User is collected and processed.</br></br>
            
            <div><h3>Service</h3></div>
            The service provided by this Application as described in the relative terms (if available) and on this site/application.</br></br>
            
            <div><h3>European Union (or EU)</h3></div>
            Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</br></br>
            
            <div><h3>Cookies</h3></div>
            Small sets of data stored in the User's device.</br></br>
            
            <div><h3>Legal information</h3></div>
            This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</br></br>
            
            This privacy policy relates solely to this Application, if not stated otherwise within this document.
        `,
    PRIVACY_DIALOG: `By continuing to browse the site, you accept the terms of the <a href="/privacy">privacy policy</a>. If you want to remove this popup click accept`,
    PRIVACY_DIALOG_CONFIRM: "OK",
    CONTACT_ERROR_NAME: "Be careful! You must compile the Name field",
    CONTACT_ERROR_EMAIL:
      "Be careful! You must compile the Email field with a valid email",
    CONTACT_ERROR_SUBJECT: "Be careful! You must compile the Subject field",
    CONTACT_ERROR_MESSAGE: "Be careful! You must compile the Message field",
    CONTACT_SUCCESS_MESSAGE: "Your message has been sent!",
  },

  /* *********************************************************************** */
  /* *********************************************************************** */
  /* *********************************************************************** */
  projects: data => {
    return [
      {
        key: "idi-project",
        years: "2022",
        position: "Visit the website!",
        title: "IDI Project",
        text: `<strong>IDI project</strong> is a website that offers information on <strong>international distribution</strong>. The site offers professionals involved in the creation of <strong>distribution networks</strong> and in the drafting, negotiation and management of <strong>distribution contracts</strong>: legal information, contractual models and experiences of other experts facing the same problems. The project manages several integrations of <strong>third-party services</strong> for both <strong>payment</strong>, membership for managing <strong>document conversion</strong> into multiple formats, for optimizing them and for <strong>synchronization with other platforms</strong> used for managing customer and company data.`,
        url: "https://www.idiproject.com/",
        img: data.idiProject.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "tekima-tubings-store",
        years: "2023",
        position: "Visit the website!",
        title: "Tubings Store",
        text: `Custom platform made in <strong>React</strong> and <strong>PHP</strong> implemented within a <strong>Wordpress</strong> ecosystem to meet the need to be able to offer a store with dynamic and adaptable filters depending on search results in an effective and light way. <br/><br/> Also supported at the <strong>responsive</strong>, multilingual level and also <strong>integrated with third-party platforms</strong> to offer a store also within other platforms for resellers communicating with their systems via <strong>Websocket</strong>.`,
        url: "https://www.tekima.com/en/tubings-store/",
        img: data.tekimaTubingsStore.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "tekima-labels-store",
        years: "2022",
        position: "Visit the website!",
        title: "Labels Store",
        text: `Custom platform made in <strong>React</strong> and <strong>PHP</strong> implemented within a <strong>Wordpress</strong> ecosystem to meet the need to be able to offer a store with dynamic and adaptable filters depending on search results in an effective and light way. <br/><br/> Also supported at the <strong>responsive</strong>, multilingual level and also <strong>integrated with third-party platforms</strong> to offer a store also within other platforms for resellers communicating with their systems via <strong>Websocket</strong>.`,
        url: "https://www.tekima.com/en/labels-store/",
        img: data.tekimaLabelsStore.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "tekima-cable-store",
        years: "2021",
        position: "Visit the website!",
        title: "Cables Store",
        text: `Custom platform made in <strong>React</strong> and <strong>PHP</strong> implemented within a <strong>Wordpress</strong> ecosystem to meet the need to be able to offer a store with dynamic and adaptable filters depending on search results in an effective and light way. <br/><br/> Also supported at the <strong>responsive</strong>, multilingual level and also <strong>integrated with third-party platforms</strong> to offer a store also within other platforms for resellers communicating with their systems via <strong>Websocket</strong>.`,
        url: "https://www.tekima.com/en/cables-store/",
        img: data.tekimaCablesStore.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "adventofcode-2022",
        years: "2022",
        position: "Visit the site!",
        title: "Advent of code 2022",
        text: `The <strong>Advent of Code</strong> is an annual event consisting of a series of small <strong>programming puzzles</strong>. <br/> The puzzles are released daily from December 1st to December 25th. The goal is to improve your understanding of your preferred programming language by solving as many puzzles as possible. I participated in the Advent of Code 2022 and found the <strong>challenges particularly interesting from a logical point of view</strong>. <br/> This event offers the opportunity to <strong>test your programming skills</strong> and solve problems creatively. <br/>`,
        url: "https://github.com/NikolasMosca/adventofcode-2022",
        img: data.adventOfCode2022.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "weather-pwa",
        years: "2021",
        position: "Visit the site!",
        title: "Web App Weather",
        text: `Test interview project.<br/>
                This web app requires displaying, with a specific template provided by the company, the <strong>current/forecasts weather for each city in the world</strong>.<br/>
                It is possible to use <strong>geolocalization to retrieve the weather data for your city</strong>.<br/>
                This PWA was created with <strong>React</strong>, <strong>Redux</strong> with hooks.<br/>
                It has end-to-end tests maded with <strong>cypress</strong>, automation test maded with <strong>Jest</strong>.<br/>
                It has component documentation maded with <strong>storybook</strong>.
                `,
        url: "https://musing-mestorf-1a7643.netlify.app/",
        img: data.weatherWebApp.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "2048-game",
        years: "2020",
        position: "Play!",
        title: "2048 Game",
        text: `Simple clone of the <strong>2048</strong> game made for fun. <br/>
                    The project use <strong>React</strong>.  <br/>
                    Enjoy! <br/><br/>
                    The project is also avaiable on <strong>github</strong>, if you like it please give me a <strong>star</strong>`,
        url: "https://festive-albattani-dc5d19.netlify.app/",
        img: data.game2048.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "tic-tac-toe",
        years: "2020",
        position: "Play!",
        title: "Tris Game",
        text: `This is a simple <strong>tris game</strong>. <br/>
                    The project use <strong>VueJS</strong>.  <br/>
                    The player can play against an <strong>AI</strong> that I made personally. <br/> 
                    It's not impossible to win but it's a bit hard :) Enjoy! <br/><br/>
                    The project is also avaiable on <strong>github</strong>, if you like it please give me a <strong>star</strong>`,
        url: "https://frosty-albattani-20d20c.netlify.com/",
        img: data.tris.childImageSharp.fluid,
        animated: false,
      },
      {
        key: "museo-del-fumetto",
        years: "2020",
        position: "Visit the site!",
        title: "Museo del fumetto",
        text: `This is a site that permits to display and manage exposure and events for comics. <br/>
                    This is a full-dynamic site made with <strong>Laravel</strong> and <strong>SASS</strong>. <br/>
                    I use migrations to manage the database <strong>MySQL</strong> and blade view <strong>components</strong> 
                    for optimizing the template.<br/>
                    The backend to edit the site is made with <strong>AngularJS + REST API Laravel</strong>`,
        url: "http://www.museowow.it/",
        img: data.museoFumetto.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "honda",
        years: "2019",
        position: "React native app!",
        title: "App for conference",
        text: `App made in <strong>React Native</strong> for <strong>IOS</strong> and <strong>Android</strong>. <br/>
                    This app is for a <strong>private</strong> use by employees in their <strong>conference</strong>. <br/>
                    It also uses <strong>OneSignal</strong> for notifications and it manages users and webservices for other functionalities`,
        url: false,
        img: data.honda.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "nimrod",
        years: "2018",
        position: "Visit the site!",
        title: "Nimrod",
        text: `Site and panel management. <br/>
                    The site is made with <strong>PHP + Javascript</strong>. <br/>
                    It uses <strong>custom code without framework</strong> to have the best quality and performance. <br/>
                    The backend is made in full <strong>PHP</strong>`,
        url: "https://www.dociliarestauri.it/",
        img: data.nimrod.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "immobilbiella",
        years: "2017",
        position: "Visit the site!",
        title: "Immobilbiella",
        text: `This is a site for selling properties. 
                    This website is made with <strong>Laravel</strong> and <strong>PHP Plain</strong>. <br/>
                    For the frontend it's used <strong>JQuery</strong> and <strong>Javascript Plain in OOP</strong> to manage all the functionalities. <br/>
                    The backend management is made by <strong>AngularJS + REST API Laravel</strong>`,
        url: "http://immobilbiella.it/",
        img: data.immobilbiella.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "docilia",
        years: "2017",
        position: "Visit the site!",
        title: "Docilia Restauri",
        text: `Site and panel management. <br/>
                    The site is made with <strong>PHP + Javascript</strong>. <br/>
                    It uses <strong>custom code without framework</strong> to have the best quality and performance. <br/>
                    The backend is made in full <strong>PHP</strong>`,
        url: "https://www.dociliarestauri.it/",
        img: data.docilia.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "azimut",
        years: "2017",
        position: "Visit the site!",
        title: "Azimut",
        text: `Site and panel management custom. <br/>
                    The site is made with <strong>PHP + Javascript</strong>. <br/>
                    It uses <strong>custom code without framework</strong> to have the best quality and performance. <br/>
                    The backend is made in <strong>Zend Framework PHP</strong>`,
        url: "https://www.dociliarestauri.it/",
        img: data.azimut.childImageSharp.fluid,
        animated: true,
      },
      {
        key: "jinni",
        years: "2016",
        position: "Visit the site!",
        title: "Jinni",
        text: `Simple <strong>webpage</strong> that I made for this project. <br/>
                    I also made the <strong>chrome extension</strong> that permits to give a short url in smart way. This url permits to have analytics to get statistics about where, when and how many users clicked it`,
        url: "https://cranky-jepsen-b1c31a.netlify.com/",
        img: data.jinni.childImageSharp.fluid,
        animated: true,
      },
    ]
  },
}
